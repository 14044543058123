<template>
  <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
    <div class="row mb-2">
      <div class="col-12 col"><h1>Relatório individual</h1></div>
      <div class="col-12 col"><p>
        Selecione o colaborador para emitir o relatório individual do Planeja Saúde.
      </p></div>
    </div>
    <section class="tabela-Pessoas">
      <b-card>
        <b-row class="mb-0 align-items-end" id="section-ciclo">
          <b-col lg="10" md="9" class="mb-0">
            <label class="font-medium-1 font-weight-bold">Selecionar fase</label>
            <v-select
              v-model="FaseSelecionada"
              :options="Fases"
              item-text="text"
              label="text"
              :clearable="false"
              :reduce="(option => option.value)"
              placeholder="Selecione uma fase..."
            >
              <span slot="no-options">Nenhuma fase encontrado.</span>
            </v-select>
          </b-col>
          <b-col lg="2" md="3">
            <b-button variant="custom" class="btn-block" :disabled="desabilitarBotao" @click="getColaboradoresPorEmpresaECiclo">
              Selecionar
            </b-button>
          </b-col>
        </b-row>
        <hr class="my-3">
        <b-row id="filtros" v-show="filtros" class="mb-1 align-items-center">
          <b-col lg="2" sm="4">
            <div class="mb-0 d-flex flex-row align-items-center">
              <label class="d-inline-block text-sm-left font-small-4 mr-1">Visualizar</label>
              <b-form-select
                id="perPageSelect"
                v-model="dadosPaginacao.quantidadeLinhasPadrao"
                size="sm"
                :options="dadosPaginacao.pageOptions"
              />
            </div>
          </b-col>
          <b-col lg="3" sm="4">
            <div
              v-if="situacaoRelatorioZip == 'Em Processamento' && relatorioEmMassaAtivado"
              class="font-weight-bold bg-warning text-white rounded p-1 d-inline-block"
            >
              <feather-icon
                icon="InfoIcon"
                size="14"
                class="mr-1"
              />
              Solicitação em processamento
            </div>
          </b-col>
          <b-col lg="4" sm="0" />
          <b-col lg="3" sm="4">
            <div class="mb-0 d-flex flex-row align-items-center">
              <label class="text-sm-left font-small-4 mr-1">Pesquisar</label>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Pesquisar"
              />
            </div>
          </b-col>
        </b-row>
        <b-row id="filtros" v-show="filtros" class="mt-1 align-items-center" v-if="relatorioEmMassaAtivado">
          <b-col lg="2" sm="3">
            <b-button
              v-if="itensTabela.length > 1"
              variant="outline-primary"
              class="btn-icon"
              @click.prevent="fireConfirmationModalGenerateZipReport"
              :disabled="loadingProcessamento || situacaoRelatorioZip == 'Em Processamento' ? true : false"
            >
              <span v-if="loadingProcessamento" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
              <span>Gerar relatórios em massa</span>
            </b-button>
          </b-col>
          <b-col lg="2" sm="3" v-if="situacaoRelatorioZip == 'Concluído'">
            <b-button
              variant="outline-primary"
              class="btn-icon"
              @click.prevent="downloadZip"
              :disabled="loadingZip || loadingProcessamento"
            >
                            <span v-if="!loadingZip">
                                <feather-icon
                                  icon="DownloadIcon"
                                  size="14"
                                  class="mr-1"
                                />
                            </span>
              <span v-if="loadingZip" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
              <span>Download do arquivo</span>
            </b-button>
          </b-col>
          <b-col lg="8" sm="6" v-if="situacaoRelatorioZip == 'Concluído'">
            <div

              class="font-weight-bold"
            >
              Gerado em: {{ dataCriacaoRelatorioZip }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-table
              v-if="dadosPaginacao.totalLinhas > 0"
              id="listPessoasTable"
              responsive="sm"
              no-border-collapse
              :per-page="dadosPaginacao.quantidadeLinhasPadrao"
              :current-page="dadosPaginacao.paginaAtual"
              sticky-header="100%"
              :busy.sync="tabela.tabelaOcupada"
              :no-local-sorting="false"
              :fields="tabela.fields"
              :items="itensTabela"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              empty-text="Não há registros para mostrar."
              class="rounded border mt-2">
              <template #cell(perfil)="data">
                {{ verificaTrabalhador(limpar(data.item.perfil)) }}
              </template>
              <template #cell(acoes)="data">
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  b-tooltip="Gerar PDF"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="gerarRelatorio(data.item)"
                >
                  Gerar
                </b-button>
              </template>
            </b-table>
            <b-alert variant="info" :show="loadingBusca">
              <div class="alert-body d-flex justify-content-center text-center">
                                <span class="text-primary">
                                  <strong class="text-primary font-medium-3">Aguarde...</strong><br>
                                  Estamos buscando os registros
                                </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" v-if="dadosPaginacao.totalLinhas > 0">
            <label class="label-amount-table mb-0 d-none d-md-block">
              Mostrando {{ dadosPaginacao.primeiroMostrado }} a
              {{ dadosPaginacao.ultimoMostrado }} de
              {{ dadosPaginacao.totalLinhas }} resultados
            </label>
            <b-pagination
              v-model="dadosPaginacao.paginaAtual"
              :total-rows="dadosPaginacao.totalLinhas"
              :per-page="dadosPaginacao.quantidadeLinhasPadrao"
              align="right"
              size="lg"
              pills
              hide-goto-end-buttons
            />
          </b-col>
        </b-row>
      </b-card>
    </section>
    <b-modal
      v-model="showModal"
      centered
      cancel-disabled
      ok-only
      ok-title="Fechar"
      size="xl"
      title="Relatório individual"
      class="p-0"
    >
      <div v-if="contentIframe">
        <b-embed type="embed" aspect="16by9" :src="urlRelatorio" allowfullscreen/>
      </div>
      <div v-else>
        {{ textModal }}
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardBody,
  BForm, BRow, BCol,
  BFormGroup, BFormInput,
  BButton, BCollapse, BAlert,
  BPagination, BTable, BFormSelect,
  BFormSelectOption,BInputGroup,BInputGroupAppend,
  BModal,VBModal,BEmbed
}
  from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
  components: {
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BCollapse,
    BAlert,
    BTable,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BEmbed,
    vSelect
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  computed: {
    desabilitarBotao: function () {
      return this.FaseSelecionada == null || this.Consultado;
    },
  },

  data() {
    return {
      showModal: false,
      contentIframe: false,

      textModal: 'Selecione uma pessoa para gerar o relatório',
      urlRelatorio: '',
      filtros: false,
      loadingShow: true,
      loadingShowBusca: true,
      loadingBusca: false,
      itensTabela: [],
      mostraTabela: false,
      relatorioEmMassaAtivado: false,
      Fases: [],
      FaseSelecionada: null,
      Consultado: false,
      filter: null,
      filterOn: [],
      dadosPaginacao: {
        totalLinhas: 0,
        quantidadeLinhasPadrao: 10,
        pageOptions: [10, 25, 50, 100],
        primeiroMostrado: 1,
        ultimoMostrado: 10,
        paginaAtual: 1,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: "",
        campoOrdenado: "",
        fields: [],
      },
      situacaoRelatorioZip: null,
      idEmpresa: this.$store.state.sessions.userData.empresa.id_empresa,
      loadingZip: false,
      dataCriacaoRelatorioZip: null,
      loadingProcessamento: false
    };
  },

  beforeMount() {
    this.tabela.fields = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "empresa", label: "Empresa", sortable: true },

      { key: "unidade", label: "unidade", sortable: true },
      { key: "perfil", label: "perfil", sortable: true },

      { key: "lider", label: "líder", sortable: true },
      { key: "acoes", label: "ações", class: "width-custom-column" },
    ];
  },

  created() {
    this.getFasesEmpresa()
  },

  methods: {
    async getFasesEmpresa() {
      let fasesFormatadas = [],
        rota = this.$helpers.preparaRotaComParametros(
          this.$api.AcompanhamentoBuscarFasesPorIdEmpresa,
          "{idEmpresa}",
          this.idEmpresa
        );
      await this.$http.get(rota).then((res) => {
        let resposta = res.data.data;
        resposta.sort((a, b) => a.descricao.localeCompare(b.descricao));
        
        resposta.forEach((fase) => {
          fasesFormatadas.push({
            value: fase.id_fase,
            text: 'Fase ' + fase.id_fase,
            relatorio: (typeof fase.relatorios !== 'undefined' && fase.relatorios[0]) ? {
              id_relatorio: fase.relatorios[0]['id_relatorio'],
              id_tipo_relatorio: fase.relatorios[0]['id_tipo_relatorio'],
              id_anexo: fase.relatorios[0]['id_anexo'],
              situacao: fase.relatorios[0]['situacao'],
              criacao: fase.relatorios[0]['criacao']
            } : null
          });
        });
        this.Fases = fasesFormatadas;
        this.loadingShow  = false;
      });
    },

    verificaEmail(item) {
      if (item == null) {
        return "Não informado";
      } else {
        return item;
      }
    },
    limpar(item) {
      item = item.replaceAll("{", "");
      item = item.replaceAll("}", "");
      item = item.replaceAll(",NULL", "");
      item = item.replaceAll("NULL", "");
      item = item.replaceAll(",", ", ");

      return item;
    },
    verificaTrabalhador(item) {
      if (item == "") {
        return "Trabalhador";
      } else {
        return item;
      }
    },
    async getColaboradoresPorEmpresaECiclo() {
      this.loadingBusca = true;
      let url = this.$api.ColaboradoresEmpresaCiclo;
      try {
        await this.$http
          .get(url, {
            params: this.lidarComParametrosBusca(),
          })
          .then((res) => {
            this.itensTabela = res.data;
            this.loadingShowBusca = false;
            this.loadingBusca = false;

            this.filtros = true
          });
      } catch (err) {
        this.loadingShowBusca = true;
        this.loadingBusca = false;
      }
    },
    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy;
      this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

      this.buscarDadosEmpresa();
    },

    lidarComParametrosBusca() {
      return {
        id_empresa: this.idEmpresa,
        id_fase: this.FaseSelecionada,
      };
    },
    gerarRelatorio(data) {
      this.contentIframe = true;
      this.urlRelatorio = '/planeja-saude/relatorio-individual?id_fase='+this.FaseSelecionada+'&id_colaborador='+data.id_colaborador;
      this.showModal = true;
    },
    onFiltered(filteredItems) {
      this.dadosPaginacao.totalLinhas = filteredItems.length
      this.dadosPaginacao.paginaAtual = 1
    },
    async fireConfirmationModalGenerateZipReport() {
      await modalGenericModel(
        'Gerar relatórios em massa',
        'Ao clicar em "Confirmar", sua solicitação ' +
        'será enviada para processamento.' +
        'Você será notificado assim que os relatórios ' +
        'estiverem disponíveis para download no formato ZIP, ' +
        'contendo todos os relatórios em PDF.',
        require('@/assets/custom-icons/cora-icons/help.png'),
        'Confirmar',
        'Cancelar'
      ).then((result) => {
        if (result.value) {
          this.generateZipReport();
        }
      });
    },
    async generateZipReport() {
      this.loadingProcessamento = true;
      await this.$http.post(this.$api.PlanejaSaude.iniciaPreparacaoArquivoZipParaRelatorioEmMassaOuDownloadZip(this.FaseSelecionada, this.idEmpresa))
        .then(() => {
          this.situacaoRelatorioZip = "Em Processamento";
        });
      this.loadingProcessamento = false;
    },
    async downloadZip() {
      this.loadingZip = true;
      await this.$http.get(this.$api.PlanejaSaude.iniciaPreparacaoArquivoZipParaRelatorioEmMassaOuDownloadZip(this.FaseSelecionada, this.idEmpresa), {
        responseType: "blob"
      })
        .then(response => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(response.data);
          link.download = response.headers["content-name-file"];

          link.click();

          URL.revokeObjectURL(link.href);
        }).catch(() => {
          modalGenericModel(
            'Erro ao baixar relatório',
            'Tente gerar um relatório novo clicando no botão '+
            '"Gerar relatórios em massa" ou entre em contato com o suporte',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Ok'
          )
        });
      this.loadingZip = false;
    },
    reiniciaDadosRelatorio(idFaseSelecionada){
      let objetoFaseSelecionada = this.Fases.find(element => element.value = idFaseSelecionada)
      if(objetoFaseSelecionada?.relatorio){
        this.situacaoRelatorioZip = objetoFaseSelecionada.relatorio.situacao;
        let objetoDataMoment = moment(objetoFaseSelecionada.relatorio.criacao);
        this.dataCriacaoRelatorioZip = objetoDataMoment.format('DD/MM/YYYY') +' às '+ objetoDataMoment.format('HH:mm')
      }
    }
  },

  watch: {
    FaseSelecionada(novoValor) {
      this.reiniciaDadosRelatorio(novoValor);
      this.Consultado = false;
    },
    itensTabela(value) {
      this.dadosPaginacao.totalLinhas = value.length;

      if (
        this.dadosPaginacao.ultimoMostrado > this.dadosPaginacao.totalLinhas
      ) {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      } else {
        this.dadosPaginacao.ultimoMostrado =
          this.dadosPaginacao.quantidadeLinhasPadrao;
      }
    },
    "dadosPaginacao.paginaAtual"() {
      this.dadosPaginacao.ultimoMostrado =
        this.dadosPaginacao.paginaAtual *
        this.dadosPaginacao.quantidadeLinhasPadrao;

      this.dadosPaginacao.primeiroMostrado =
        this.dadosPaginacao.ultimoMostrado -
        this.dadosPaginacao.quantidadeLinhasPadrao +
        1;

      if (
        this.dadosPaginacao.ultimoMostrado > this.dadosPaginacao.totalLinhas
      ) {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      }
    },
    "dadosPaginacao.quantidadeLinhasPadrao"(value) {
      this.dadosPaginacao.ultimoMostrado = value;
      if (
        this.dadosPaginacao.ultimoMostrado > this.dadosPaginacao.totalLinhas
      ) {
        this.dadosPaginacao.ultimoMostrado = this.dadosPaginacao.totalLinhas;
      }
    },
  }
};
</script>
